.htmlPanel {
  max-width: 960px;
  margin: auto;

  span.aos_effect_up,
  span.aos_effect_down,
  span.aos_effect_left,
  span.aos_effect_right {
    display: inline-block;
  }
}

.htmlPanel .styled {
  opacity: 0.7;
}

.htmlPanel .styled:hover {
  opacity: 1;
}

div.imagePanel{
  position: relative;
}

div.imagePanel .htmlPanel{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img{
    max-width: 60%; 
    @media (min-width: 456px) { 
      //max-width: 26%;
      max-width: 60%;
    }
    @include media-breakpoint-up(sm) { // 576px and up
      //max-width: 18%;
      max-width: 40%;
    }
    @include media-breakpoint-up(md) { // 768px and
      //max-width: 18%;
      max-width: 40%;
    }
    @include media-breakpoint-up(lg) { // 992px and up
      max-width: 40%;
    }
    // @include media-breakpoint-up(xl) { // 1200px and up
    //   max-width: 20%;
    // }
    @media (min-width: 1601px) {
      max-width: 100%;
    }
  }
}