// small page tile
.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: $family-base;
  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}
.pageTile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    .tile__bg {
      transform: scale(1.02);
    }
  }
}

.pageTile__title {
  font-size: 1.1rem;
  text-transform: capitalize;
  line-height: 1;
  //margin: 0.5rem 0;
  color: #f2f2f2;
  padding: 0.5rem 0;
  font-family: $family-header;
  font-weight: 400;
}

.pageTile__yellow__line {
  width: 2rem;
  height: 2px;
  background-color: #d96d2b;
  margin: 0.2rem 0 1rem;
  @media screen and (min-width: 576px) {
    margin: 0.5rem 0 1.5rem;
  }
}
.pageTile_desc {
  line-height: 1.2;
  font-size: 0.8rem;
  color: #f2f2f2;
  min-height: 8rem;
}
.pageTile__btn {
  background: transparent;
  color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: inline-block;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  margin-top: 1.2rem;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  @media screen and (min-width: 576px) {
    margin-top: 2rem;
  }
}
.pageTile__content:hover .pageTile__btn span {
  color: #202123;
}
.pageTile__content:hover .pageTile_details .pageTile__btn {
  border: 1px solid #d96d2b;
  color:#000;
  background-color: $color-yellow;
}
.pageTile__content:hover .tile_btn_animation::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}
.pageTile_details {
  display: inline-block;
  width: calc(100%);
  background-color: #d96d2b;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  flex-grow: 1;
  margin-top: 1rem;
  padding: 0.5rem 0.8rem;
  text-align: left;
  height: 200px;
  // display: -webkit-box;
  -webkit-line-clamp: 4;
  //   -webkit-box-orient: vertical;

  @include media-breakpoint-up(lg) {
    height: 255px;
  }
}

.pageTile__content:hover .pageTile_details {
  color:#000;
  background-color: $color-yellow;
}

.pageTile__content:hover .pageTile_details .pageTile__title,
.pageTile__content:hover .pageTile_details .pageTile_desc {
  color:#000;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }
  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

// big page tile
.pageTile__bsyc__content:hover .pageTile__bsyc__content__details .pageTile__bsyc__title {
  color: #d96d2b;
}
.pageTile__bsyc__content:hover .pageTile__bsyc__btn span {
  color: #202123;
}
.pageTile__bsyc__content:hover .pageTile__bsyc__container .pageTile__bsyc__btn {
  border: 1px solid #d96d2b;
}
.tile_btn_animation::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  background-color: #d96d2b;
  border-radius: 30px;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
}
.pageTile__bsyc__content:hover .tile_btn_animation::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}
